<template>
    <div>
        <!--begin::Items-->
        <div class="scroll-y mh-200px mh-lg-325px">
            <!--begin::Category title-->
            <h3 class="fs-5 text-muted m-0 pb-5">Users</h3>
            <!--end::Category title-->
            <!--begin::Item-->
            <a href="#" class="d-flex text-dark text-hover-primary align-items-center mb-5">
                <!--begin::Symbol-->
                <div class="symbol symbol-40px symbol-circle me-4">
                    <img src="/media/avatars/150-1.jpg" alt=""/>
                </div>
                <!--end::Symbol-->
                <!--begin::Title-->
                <div class="d-flex flex-column justify-content-start fw-bold">
                    <span class="fs-6 fw-bold">Karina Clark</span>
                    <span class="fs-7 fw-bold text-muted">Marketing Manager</span>
                </div>
                <!--end::Title-->
            </a>
            <!--end::Item-->
            <!--begin::Item-->
            <a href="#" class="d-flex text-dark text-hover-primary align-items-center mb-5">
                <!--begin::Symbol-->
                <div class="symbol symbol-40px symbol-circle me-4">
                    <img src="/media/avatars/150-3.jpg" alt=""/>
                </div>
                <!--end::Symbol-->
                <!--begin::Title-->
                <div class="d-flex flex-column justify-content-start fw-bold">
                    <span class="fs-6 fw-bold">Olivia Bold</span>
                    <span class="fs-7 fw-bold text-muted">Software Engineer</span>
                </div>
                <!--end::Title-->
            </a>
            <!--end::Item-->
            <!--begin::Item-->
            <a href="#" class="d-flex text-dark text-hover-primary align-items-center mb-5">
                <!--begin::Symbol-->
                <div class="symbol symbol-40px symbol-circle me-4">
                    <img src="/media/avatars/150-8.jpg" alt=""/>
                </div>
                <!--end::Symbol-->
                <!--begin::Title-->
                <div class="d-flex flex-column justify-content-start fw-bold">
                    <span class="fs-6 fw-bold">Ana Clark</span>
                    <span class="fs-7 fw-bold text-muted">UI/UX Designer</span>
                </div>
                <!--end::Title-->
            </a>
            <!--end::Item-->
            <!--begin::Item-->
            <a href="#" class="d-flex text-dark text-hover-primary align-items-center mb-5">
                <!--begin::Symbol-->
                <div class="symbol symbol-40px symbol-circle me-4">
                    <img src="/media/avatars/150-11.jpg" alt=""/>
                </div>
                <!--end::Symbol-->
                <!--begin::Title-->
                <div class="d-flex flex-column justify-content-start fw-bold">
                    <span class="fs-6 fw-bold">Nick Pitola</span>
                    <span class="fs-7 fw-bold text-muted">Art Director</span>
                </div>
                <!--end::Title-->
            </a>
            <!--end::Item-->
            <!--begin::Item-->
            <a href="#" class="d-flex text-dark text-hover-primary align-items-center mb-5">
                <!--begin::Symbol-->
                <div class="symbol symbol-40px symbol-circle me-4">
                    <img src="/media/avatars/150-12.jpg" alt=""/>
                </div>
                <!--end::Symbol-->
                <!--begin::Title-->
                <div class="d-flex flex-column justify-content-start fw-bold">
                    <span class="fs-6 fw-bold">Edward Kulnic</span>
                    <span class="fs-7 fw-bold text-muted">System Administrator</span>
                </div>
                <!--end::Title-->
            </a>
            <!--end::Item-->
            <!--begin::Category title-->
            <h3 class="fs-5 text-muted m-0 pt-5 pb-5">Customers</h3>
            <!--end::Category title-->
            <!--begin::Item-->
            <a href="#" class="d-flex text-dark text-hover-primary align-items-center mb-5">
                <!--begin::Symbol-->
                <div class="symbol symbol-40px symbol-circle me-4">
          <span class="symbol-label bg-light">
            <img class="w-20px h-20px" src="/media/svg/brand-logos/volicity-9.svg" alt=""/>
          </span>
                </div>
                <!--end::Symbol-->
                <!--begin::Title-->
                <div class="d-flex flex-column justify-content-start fw-bold">
                    <span class="fs-6 fw-bold">Company Rbranding</span>
                    <span class="fs-7 fw-bold text-muted">UI Design</span>
                </div>
                <!--end::Title-->
            </a>
            <!--end::Item-->
            <!--begin::Item-->
            <a href="#" class="d-flex text-dark text-hover-primary align-items-center mb-5">
                <!--begin::Symbol-->
                <div class="symbol symbol-40px symbol-circle me-4">
          <span class="symbol-label bg-light">
            <img class="w-20px h-20px" src="/media/svg/brand-logos/tvit.svg" alt=""/>
          </span>
                </div>
                <!--end::Symbol-->
                <!--begin::Title-->
                <div class="d-flex flex-column justify-content-start fw-bold">
                    <span class="fs-6 fw-bold">Company Re-branding</span>
                    <span class="fs-7 fw-bold text-muted">Web Development</span>
                </div>
                <!--end::Title-->
            </a>
            <!--end::Item-->
            <!--begin::Item-->
            <a href="#" class="d-flex text-dark text-hover-primary align-items-center mb-5">
                <!--begin::Symbol-->
                <div class="symbol symbol-40px symbol-circle me-4">
          <span class="symbol-label bg-light">
            <img class="w-20px h-20px" src="/media/svg/misc/infography.svg" alt=""/>
          </span>
                </div>
                <!--end::Symbol-->
                <!--begin::Title-->
                <div class="d-flex flex-column justify-content-start fw-bold">
                    <span class="fs-6 fw-bold">Business Analytics App</span>
                    <span class="fs-7 fw-bold text-muted">Administration</span>
                </div>
                <!--end::Title-->
            </a>
            <!--end::Item-->
            <!--begin::Item-->
            <a href="#" class="d-flex text-dark text-hover-primary align-items-center mb-5">
                <!--begin::Symbol-->
                <div class="symbol symbol-40px symbol-circle me-4">
          <span class="symbol-label bg-light">
            <img class="w-20px h-20px" src="/media/svg/brand-logos/leaf.svg" alt=""/>
          </span>
                </div>
                <!--end::Symbol-->
                <!--begin::Title-->
                <div class="d-flex flex-column justify-content-start fw-bold">
                    <span class="fs-6 fw-bold">EcoLeaf App Launch</span>
                    <span class="fs-7 fw-bold text-muted">Marketing</span>
                </div>
                <!--end::Title-->
            </a>
            <!--end::Item-->
            <!--begin::Item-->
            <a
                href="#"
                class="d-flex text-dark text-hover-primary align-items-center mb-5"
            >
                <!--begin::Symbol-->
                <div class="symbol symbol-40px symbol-circle me-4">
          <span class="symbol-label bg-light">
            <img class="w-20px h-20px" src="/media/svg/brand-logos/tower.svg" alt=""/>
          </span>
                </div>
                <!--end::Symbol-->
                <!--begin::Title-->
                <div class="d-flex flex-column justify-content-start fw-bold">
                    <span class="fs-6 fw-bold">Tower Group Website</span>
                    <span class="fs-7 fw-bold text-muted">Google Adwords</span>
                </div>
                <!--end::Title-->
            </a>
            <!--end::Item-->
            <!--begin::Category title-->
            <h3 class="fs-5 text-muted m-0 pt-5 pb-5">Projects</h3>
            <!--end::Category title-->
            <!--begin::Item-->
            <a href="#" class="d-flex text-dark text-hover-primary align-items-center mb-5">
                <!--begin::Symbol-->
                <div class="symbol symbol-40px symbol-circle me-4">
          <span class="symbol-label bg-light">
            <span class="svg-icon svg-icon-2 svg-icon-primary">
              <inline-svg src="/media/icons/duotune/general/gen005.svg"/>
            </span>
          </span>
                </div>
                <!--end::Symbol-->
                <!--begin::Title-->
                <div class="d-flex flex-column">
                    <span class="fs-6 fw-bold">Si-Fi Project by AU Themes</span>
                    <span class="fs-7 fw-bold text-muted">#45670</span>
                </div>
                <!--end::Title-->
            </a>
            <!--end::Item-->
            <!--begin::Item-->
            <a href="#" class="d-flex text-dark text-hover-primary align-items-center mb-5">
                <!--begin::Symbol-->
                <div class="symbol symbol-40px symbol-circle me-4">
          <span class="symbol-label bg-light">
            <span class="svg-icon svg-icon-2 svg-icon-primary">
              <inline-svg src="/media/icons/duotune/general/gen032.svg"/>
            </span>
          </span>
                </div>
                <!--end::Symbol-->
                <!--begin::Title-->
                <div class="d-flex flex-column">
                    <span class="fs-6 fw-bold">Shopix Mobile App Planning</span>
                    <span class="fs-7 fw-bold text-muted">#45690</span>
                </div>
                <!--end::Title-->
            </a>
            <!--end::Item-->
            <!--begin::Item-->
            <a href="#" class="d-flex text-dark text-hover-primary align-items-center mb-5">
                <!--begin::Symbol-->
                <div class="symbol symbol-40px symbol-circle me-4">
          <span class="symbol-label bg-light">
            <span class="svg-icon svg-icon-2 svg-icon-primary">
              <inline-svg src="/media/icons/duotune/communication/com012.svg"/>
            </span>
          </span>
                </div>
                <!--end::Symbol-->
                <!--begin::Title-->
                <div class="d-flex flex-column">
                    <span class="fs-6 fw-bold">Finance Monitoring SAAS Discussion</span>
                    <span class="fs-7 fw-bold text-muted">#21090</span>
                </div>
                <!--end::Title-->
            </a>
            <!--end::Item-->
            <!--begin::Item-->
            <a href="#" class="d-flex text-dark text-hover-primary align-items-center mb-5">
                <!--begin::Symbol-->
                <div class="symbol symbol-40px symbol-circle me-4">
          <span class="symbol-label bg-light">
            <span class="svg-icon svg-icon-2 svg-icon-primary">
              <inline-svg src="/media/icons/duotune/communication/com006.svg"/>
            </span>
          </span>
                </div>
                <!--end::Symbol-->
                <!--begin::Title-->
                <div class="d-flex flex-column">
                    <span class="fs-6 fw-bold">Dashboard Analitics Launch</span>
                    <span class="fs-7 fw-bold text-muted">#34560</span>
                </div>
                <!--end::Title-->
            </a>
            <!--end::Item-->
        </div>
        <!--end::Items-->
    </div>
</template>

<script>
export default {
    name: "kt-results",
    components: {}
}
</script>